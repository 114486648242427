import React from "react";
import {useState } from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import { axiosInstance } from "config/axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from "react-helmet";

const Contact = () =>{
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    query: "",
  });

  const [submissionStatus, setSubmissionStatus] = useState(null);
const [showSuccessMessage, setShowSuccessMessage] = useState(false);
const [errorMessage, setErrorMessage] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Phone number validation regex
    const phoneRegex = /^\d{10}$/;
    if (!formData.fullName || !formData.email || !formData.phoneNumber || !formData.query) {
      setSubmissionStatus("error");
      setErrorMessage("All fields are required.");
      return;
    }
    if (!emailRegex.test(formData.email)) {
      setSubmissionStatus("error");
      setErrorMessage("Please enter a valid email address.");
      return;
    }
  
    if (!phoneRegex.test(formData.phoneNumber)) {
      setSubmissionStatus("error");
      setErrorMessage("Please enter a valid 10-digit phone number.");
      return;
    }
    try {
      const response = await axiosInstance.post("/save-query", formData);
      const result = response.data;

      console.log('Response:', response);
      console.log('Result:', result);

      if (result.status) {
        setSubmissionStatus("success");
        setShowSuccessMessage(true);
        setTimeout(() => {
          setSubmissionStatus(null);
        }, 3000);
        setFormData({
          fullName: "",
          email: "",
          phoneNumber: "",
          query: "",
      });
      // Trigger toast notification
  toast.success("We will contact you shortly.", {
    position: toast.POSITION.TOP_RIGHT
  });
      } else {
        setSubmissionStatus("error");
      }
    } catch (error) {
      console.error("Error:", error);
      setSubmissionStatus("error");
    }
  };

return(
<div>
          <Helmet>
            <link rel="canonical" href="https://oneaichat.com/contact" />
          </Helmet>
<Header />
     <main className="wrapper">
  {/* Breadcrumb Start */}
      <section
        className="aai-breadcrumb"
      //   style={{ background: "url('assets/img/bg/bread-crumb-bg.jpeg') no-repeat center center/cover" }}
       >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-auto">
              <div className="text-center">
                <h1 className="aai-page-title">Contact Us</h1>
                <ul className="aai-breadcrumb-nav d-flex align-items-center justify-content-center">
                  <li>
                    <a
                      href="/"
                      className="aai-breadcrumb-link text-decoration-underline"
                    >
                      Home
                    </a>
                  </li>
                  <li>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14 16L18 12M18 12L14 8M18 12L6 12"
                        stroke="#DADADA"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </li>
                  <li>
                    <a href="" className="aai-breadcrumb-link">
                      Contact Us
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
  {/* Breadcrumb End */}
<center>
  <div className="imgg">
  <img src="assets/img/hero/contact.png" alt="" width={"50%"} style={{borderRadius:"25px"}} />
  </div>
  </center>
<br/>
      {/* Contact Form Start*/}
      <section className="aai-contact-form">
        <div className="container">
          <div className="row g-5 align-items-center justify-content-between">
            <div className="col-xl-6 col-lg pe-lg-5">
              <div>
                <h2 className="section-title mb-5" style={{marginLeft:"-160px"}} >
                  We love to collab!
                </h2>
                <div className="">
                  <div className="aai-contact-widget mb-4">
                    <div className="aai-contact-widget-inner d-flex">
                      <div>
                        <img src="assets/img/icons/contact-1.svg" alt="" />
                      </div>
                      <div>
                        <h3 className="aai-contact-widget-title">Our Address</h3>
                        <address className="aai-contact-address">
                        KALETECH PRIVATE LIMITED,
                        <br/>
                        THERESA GARDEN-STREAK APT, FLAT NO 320, OPP BONDEL 
                        CHURCH, AIRPORT ROAD, MARAKADA VILLAGE, MANGALORE-
                        575008
                        <br/>
                        <br/>
                        ಕೆಲ್ಟೆಕ್ ಪ್ರೈವೆಟ್ ಲಿಮಿಟೆಡ್,
                        <br/>
                        ಥೆರೆಸಾ ಗಾರ್ಡನ್-ಸ್ಟ್ರೀಕ್ ಆಪ್ಟಿ, ಫ್ಲಾಟ್ ನಂ 320, ಬೋಂಡೆಲ್ ಎದುರು
                        ಚರ್ಚ್, ವಿಮಾನ ನಿಲ್ದಾಣ ರಸ್ತೆ, ಮರಕಡ ಗ್ರಾಮ, ಮಂಗಳೂರು-
                        575008
                        <br/>
                        <br/>
                        केलटेक प्राइवेट लिमिटेड,
                        <br/>
                        थेरेसा गार्डन-स्ट्रीक अपत फ्लैट नं ३२० ऑप बॉन्डेल चर्च हवाई अड्डे रोड माराकाडा गांव मंगलौर-५७५००८
                        </address>
                      </div>
                    </div>
                  </div>
                  <div className="aai-contact-widget mb-4">
                    <div className="aai-contact-widget-inner d-flex">
                      <div>
                        <img src="assets/img/icons/contact-2.svg" alt="" />
                      </div>
                      <div>
                        <h3 className="aai-contact-widget-title">Contact Info</h3>
                        <p className="aai-contact-support">
                          Write us a note or give a call at  
                        </p>
                        <a href="tel:9844383338" className="aai-contact-address">
                          +91 9844383338
                        </a>
                        <br/>
                        <a href="tel:9844383338" className="aai-contact-address">
                        media@oneaichat.com 
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg">
              <div className="aai-contact-form">
                <form  onSubmit={handleSubmit}>
                  <div className="row align-items-center justify-content-between">
                    <div className="col-xl-6">
                      <div className="aai-form-input">
                        <input
                          type="text"
                          className="form-control shadow-none"
                          placeholder="Full Name"
                          name="fullName"
                          value={formData.fullName}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="aai-form-input">
                        <input
                          type="text"
                          className="form-control shadow-none"
                          placeholder="Email"
                          name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            required
                        />
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="aai-form-input">
                        <input
                          type="tel"
                          className="form-control shadow-none"
                          placeholder="Phone Number"
                          name="phoneNumber"
                            value={formData.phoneNumber}
                            onChange={handleInputChange}
                            required
                            maxLength="10" // Set the maximum length (adjust as needed)
                        />
                      </div>
                    </div>
                    <div className="col-xl-12">
                      <div className="aai-form-input">
                        <textarea
                          className="form-control shadow-none text-area"
                          placeholder="Query"
                          name="query"
                            value={formData.query}
                            onChange={handleInputChange}
                            required
                        ></textarea>
                      </div>
                    </div>
                    {/* Display submission status */}
          
          {submissionStatus === "error" && (
            <p style={{ color: "red" }}>{errorMessage}</p>
          )}
          <br/>
          <br/>
                    <div className="col-xl-12">
                      <div className="d-flex justify-content-start">
                        <button className="aai-btn btn-pill-solid" type="submit" >
                          Send A Query
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
                
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Contact Form End */}

 {/* Faq Start */}
      <section className="aai-faq py-120">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-xl-5 col-lg">
              <div className="secttionn">
                <h2 className="section-title mb-4">
                  Any <span className="gradient-text">Questions?</span> <br />
                  We have Answers!
                </h2>
                <p className="section-desc" style={{textAlign:"center"}}>
                 <b>Don’t find answers here? </b> 
                </p>
                <div className="mt-5"style={{marginLeft:"12rem"}}>
                  <a href="/contact" className="aai-gradient-outline-btn">
                    Contact Us
                  </a>
                </div>
              </div>
            </div>
            <div
              className="col-xl-6 col-lg mt-5 mt-xl-0"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="aai-accordions">
                <div
                  className="accordion accordion-flush"
                  id="accordionFlushExample"
                >
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button shadow-none collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseOne"
                        aria-expanded="false"
                        aria-controls="flush-collapseOne"
                      >
                        What is OneAI-Chat?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseOne"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        <p className="aai-accordion-text">
                        Deep learning, Machine learning and Artificial 
                        Intelligence service company. Give a spin to 
                        our native bot ‘Somebody’.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button shadow-none"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseTwo"
                        aria-expanded="false"
                        aria-controls="flush-collapseTwo"
                      >
                        Tell me more about OneAI-Chat?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseTwo"
                      className="accordion-collapse collapse show"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        <p className="aai-accordion-text">
                        OneAI-Chat is a AI aggregation platform with 
                        multimodal offerings, distributed in focused 
                        categories. 
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button shadow-none collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseThree"
                        aria-expanded="false"
                        aria-controls="flush-collapseThree"
                      >
                      Why do I need this?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseThree"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        <p className="aai-accordion-text">
                        To get brief answers to the big questions.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button shadow-none collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseFour"
                        aria-expanded="false"
                        aria-controls="flush-collapseFour"
                      >
                      Why do I pay for this? 
                      </button>
                    </h2>
                    <div
                      id="flush-collapseFour"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        <p className="aai-accordion-text">
                        Can get answers from OneAI’s category 
                        focused services and best in class global AI 
                        services, along with necessary features, all on 
                        one platform. 
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button shadow-none collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseFive"
                        aria-expanded="false"
                        aria-controls="flush-collapseFive"
                      >
                        Tell me more about features?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseFive"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">
                        <p className="aai-accordion-text">
                        Subscription service offers you to upload a 
                        document, copy a message, share a message 
                        from focused category.  <br/>
                        ‘Uploading a document’ provides you to 
                          extract important outputs from your own huge 
                          datasets or files. 
                          ‘Copying a message’ adds on to your 
                          productivity. 
                          <br/>
                          ‘Sharing a message’ adds on to your comfort. 
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
   {/*    Faq End*/}
  {/* Cta Start */}
     <section
        className="aai-cta pb-120"
        // style={{
        //   background: "url('assets/img/bg/cta-bg.jpeg') no-repeat center center/cover"
        // }}
      >
        <div className="container" style={{"border":"1px solid #28c434","borderRadius":"25px"}}>
          <div className="row align-items-center">
            <div className="col-xl-5 col-lg">
              <div className="secttionn">
                <h2 className="section-title mb-4">
                The Future Isn't What It Used To Be!&nbsp;
                  <span className="position-relative">
                  
                  </span>
                  
                </h2>
            
                <div className="mt-5 d-flex flex-column flex-md-row aai-btns-group">
                  <a href="/pricing" className="aai-btn btn-pill-solid">
                    Get Started
                  </a>
                  <a href="/about" className="aai-gradient-outline-btn">
                    Learn More
                  </a>
                </div>
              </div>
            </div>
            <div
              className="col-xl-7 col-lg mt-5 mt-xl-0"
              data-aos="fade-up"
              data-aos-delay="80"
            >
              <div className="aai-cta-img">
                <img
                  src="assets/img/logo/2.jpeg"
                  className="img-fluuid"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Cta End */}






        
     </main>
 <Footer />
</div>

    )
}
export default Contact;