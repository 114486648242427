import React from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import { Helmet } from "react-helmet";

const Services = () => {
  return (
    <div>
      {/* <Helmet>
      <link rel="canonical" href="https://oneaichat.com/services" />
      </Helmet> */}

      <Helmet>
        <title>AI Chat Website: Boost Your Business with Smart Solutions</title>
        <meta
          name="description"
          content="Explore advanced AI chatbot services for quick, engaging conversations. Enhance customer experience, automate tasks, and increase efficiency with OneAIChat."
        />
        <meta name="keywords" content="AI Chat website" />
        <link rel="canonical" href="https://oneaichat.com/services" />
      </Helmet>
      <Header />
      <main className="wrapper">
        {/* Breadcrumb Start */}
        <section
          className="aai-breadcrumb"
          // style={{
          //   background: "url('assets/img/bg/bread-crumb-bg.jpeg') no-repeat center center/cover"
          // }}
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-auto">
                <div className="text-center">
                  <h1 className="aai-page-title">Services</h1>
                  <ul className="aai-breadcrumb-nav d-flex align-items-center justify-content-center">
                    <li>
                      <a
                        href="/"
                        className="aai-breadcrumb-link text-decoration-underline"
                      >
                        Home
                      </a>
                    </li>
                    <li>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14 16L18 12M18 12L14 8M18 12L6 12"
                          stroke="#DADADA"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </li>
                    <li>
                      <a href="" className="aai-breadcrumb-link">
                        {" "}
                        Services{" "}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Breadcrumb End */}
        {/* Aai Feature */}
        <section
          className="aai-features pb-120"
          // style={{
          //   background: "url('assets/img/bg/aai-feature-bg.jpeg') no-repeat center center/cover"
          // }}
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-8">
                <div className="aai-section-title mb-3 text-center mb-5">
                  <h2 className="section-title">
                    Oneaichat Generate content in seconds
                  </h2>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div
                className="col-lg-4 col-md-6 mb-4"
                data-aos="fade-up"
                data-aos-delay="50"
              >
                <div className="aai-feature-box">
                  <div className="aai-feature-box-inner d-flex flex-column align-items-center">
                    <h3 className="aai-feature-title">Image Generation</h3>
                    <p
                      className="aai-feature-desc"
                      style={{ textAlign: "justify" }}
                    >
                      Photo-realistic scenes from descriptions like landscapes,
                      portraits, product shots etc. Concept art for movies,
                      games, books based on written prompts. Infographics,
                      diagrams and data visualizations for reports or
                      presentations. Medical images like X-rays, CT scans for
                      training or evaluation.
                    </p>
                    <a href="/servicedetails" className="aai-feature-link">
                      <svg
                        width="37"
                        height="13"
                        viewBox="0 0 37 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M29.375 1L35.055 6.27428L29.375 11.9543"
                          stroke="currentColor"
                          strokeOpacity="0.80"
                          strokeWidth="1.52"
                          strokeLinecap="round"
                        />
                        <path
                          d="M34 6.25L1 6.25"
                          stroke="currentColor"
                          strokeOpacity="0.80"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6 mb-4"
                data-aos="fade-up"
                data-aos-delay="60"
              >
                <div className="aai-feature-box">
                  <div className="aai-feature-box-inner d-flex flex-column align-items-center">
                    <h3 className="aai-feature-title">Video Creation</h3>
                    <p
                      className="aai-feature-desc"
                      style={{ textAlign: "justify" }}
                    >
                      Animated explanatory or promotional videos without video
                      editing. Simulated security footage or scenarios for
                      training purposes. Custom cinematic trailers, title
                      sequences based on written narratives. Timelapse, stop
                      motion videos from individual images.
                    </p>
                    <a href="/servicedetails" className="aai-feature-link">
                      <svg
                        width="37"
                        height="13"
                        viewBox="0 0 37 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M29.375 1L35.055 6.27428L29.375 11.9543"
                          stroke="currentColor"
                          strokeOpacity="0.80"
                          strokeWidth="1.52"
                          strokeLinecap="round"
                        />
                        <path
                          d="M34 6.25L1 6.25"
                          stroke="currentColor"
                          strokeOpacity="0.80"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6 mb-4"
                data-aos="fade-up"
                data-aos-delay="70"
              >
                <div className="aai-feature-box">
                  <div className="aai-feature-box-inner d-flex flex-column align-items-center">
                    <h3 className="aai-feature-title">
                      Audio & Music Generation
                    </h3>
                    <p
                      className="aai-feature-desc"
                      style={{ textAlign: "justify" }}
                    >
                      Original songs, soundtracks or jingles based on lyrical
                      ideas. Narrated audiobooks or podcast episodes from
                      textual sources. Educational audio clips, narrated lessons
                      or lectures. Custom sound effects, Foley recordings for
                      movies or games.
                    </p>
                    <a href="/servicedetails" className="aai-feature-link">
                      <svg
                        width="37"
                        height="13"
                        viewBox="0 0 37 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M29.375 1L35.055 6.27428L29.375 11.9543"
                          stroke="currentColor"
                          strokeOpacity="0.80"
                          strokeWidth="1.52"
                          strokeLinecap="round"
                        />
                        <path
                          d="M34 6.25L1 6.25"
                          stroke="currentColor"
                          strokeOpacity="0.80"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6 mb-4"
                data-aos="fade-up"
                data-aos-delay="80"
              >
                <div className="aai-feature-box">
                  <div className="aai-feature-box-inner d-flex flex-column align-items-center">
                    <h3 className="aai-feature-title">Text Generation</h3>
                    <p
                      className="aai-feature-desc"
                      style={{ textAlign: "justify" }}
                    >
                      Long form blogs, articles, stories based on topics.
                      Software documentation, technical manuals or white papers.
                      Email templates, marketing collateral like brochures,
                      catalogues. Screenplays, subtitles, closed captions for
                      videos.
                    </p>
                    <a href="/servicedetails" className="aai-feature-link">
                      <svg
                        width="37"
                        height="13"
                        viewBox="0 0 37 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M29.375 1L35.055 6.27428L29.375 11.9543"
                          stroke="currentColor"
                          strokeOpacity="0.80"
                          strokeWidth="1.52"
                          strokeLinecap="round"
                        />
                        <path
                          d="M34 6.25L1 6.25"
                          stroke="currentColor"
                          strokeOpacity="0.80"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6 mb-4"
                data-aos="fade-up"
                data-aos-delay="90"
              >
                <div className="aai-feature-box">
                  <div className="aai-feature-box-inner d-flex flex-column align-items-center">
                    <h3 className="aai-feature-title">Document Generation</h3>
                    <p
                      className="aai-feature-desc"
                      style={{ textAlign: "justify" }}
                    >
                      Personalized form letters, contracts, invoices with merge
                      fields. Financial reports, forecasts based on numerical
                      data. Medical notes, prescriptions based on patient
                      records. Legal briefs summarizing case
                      details.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </p>
                    <a href="/servicedetails" className="aai-feature-link">
                      <svg
                        width="37"
                        height="13"
                        viewBox="0 0 37 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M29.375 1L35.055 6.27428L29.375 11.9543"
                          stroke="currentColor"
                          strokeOpacity="0.80"
                          strokeWidth="1.52"
                          strokeLinecap="round"
                        />
                        <path
                          d="M34 6.25L1 6.25"
                          stroke="currentColor"
                          strokeOpacity="0.80"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6 mb-4"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="aai-feature-box">
                  <div className="aai-feature-box-inner d-flex flex-column align-items-center">
                    <h3 className="aai-feature-title">
                      11 categories for focused work
                    </h3>
                    <p
                      className="aai-feature-desc"
                      style={{ textAlign: "justify" }}
                    >
                      <ol>
                        <li>Health</li>
                        <li>Marketing</li>
                        <li>Coding</li>
                        <li>Faith</li>
                        <li>Mathematics</li>
                        <li>Video</li>
                        <li>Finance</li>
                        <li>Design/Art</li>
                        <li>Science</li>
                        <li>Music/Audio</li>
                        <li>Writing</li>
                      </ol>
                    </p>
                    <a href="/servicedetails" className="aai-feature-link">
                      <svg
                        width="37"
                        height="13"
                        viewBox="0 0 37 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M29.375 1L35.055 6.27428L29.375 11.9543"
                          stroke="currentColor"
                          strokeOpacity="0.80"
                          strokeWidth="1.52"
                          strokeLinecap="round"
                        />
                        <path
                          d="M34 6.25L1 6.25"
                          stroke="currentColor"
                          strokeOpacity="0.80"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="text-center mt-5"
              data-aos="fade-up"
              data-aos-delay="110"
            >
              <a href="/servicedetails" className="aai-btn btn-pill-solid">
                {" "}
                See More
              </a>
            </div>
          </div>
        </section>
        {/* Aai Feature  End*/}
        {/* Cta Start */}
        <section
          className="aai-cta pb-120"
          // style={{
          //   background: "url('assets/img/bg/cta-bg.jpeg') no-repeat center center/cover"
          // }}
        >
          <div
            className="container"
            style={{ border: "1px solid #28c434", borderRadius: "25px" }}
          >
            <div className="row align-items-center">
              <div className="col-xl-5 col-lg">
                <div className="secttionn">
                  <h2 className="section-title mb-4">
                    The Future Isn't What It Used To Be!&nbsp;
                    <span className="position-relative"></span>
                  </h2>

                  <div className="mt-5 d-flex flex-column flex-md-row aai-btns-group">
                    <a href="/pricing" className="aai-btn btn-pill-solid">
                      Get Started
                    </a>
                    <a href="/about" className="aai-gradient-outline-btn">
                      Learn More
                    </a>
                  </div>
                </div>
              </div>
              <div
                className="col-xl-7 col-lg mt-5 mt-xl-0"
                data-aos="fade-up"
                data-aos-delay="80"
              >
                <div className="aai-cta-img">
                  <img
                    src="assets/img/logo/2.jpeg"
                    className="img-fluuid"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Cta End */}
      </main>
      <Footer />
    </div>
  );
};
export default Services;
