import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ThemeProvider } from "../pages/ThemeContext";
import axios from "axios";
import "./PaymentSucess.css";
import { axiosInstance } from "config/axios";

function PaymentSuccess() {
  const storedPlanName = localStorage.getItem("selectedPlanName");
  const [planName, setPlanName] = useState("");
  const [planPrice, setPlanPrice] = useState(0);
  const storedPlanPrice = localStorage.getItem("selectedPlanPrice");
  const navigate = useNavigate();
  const goToHomePage = () => {
    navigate("/"); // Assuming that your home page route is '/'
  };
  const logoutHandler = () => {
    localStorage.clear();
    window.location.assign("/");
  };
  const [stripeResponse, setStripeResponse] = useState(
    JSON.parse(localStorage.getItem("stripeDetails")) || null
  );
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [theme, setTheme] = useState("system"); // default to system

  const handleThemeChange = (e) => {
    const selectedTheme = e.target.value;

    setTheme(selectedTheme);

    if (selectedTheme === "light") {
      document.documentElement.setAttribute("data-theme", "light");
    } else if (selectedTheme === "dark") {
      document.documentElement.setAttribute("data-theme", "dark");
    } else {
      document.documentElement.removeAttribute("data-theme");
    }
  };

  const initialStartDate = new Date();
  // This function will calculate the end date based on the plan name
  function calculateEndDate(planName, startDate) {
    if (!planName) {
      return startDate;
    }

    let endDate = new Date(startDate);
    switch (planName.toLowerCase()) {
      case "daily":
        endDate.setDate(endDate.getDate() + 1); // Add one day for daily plans
        break;
      case "weekly":
        endDate.setDate(endDate.getDate() + 7);
        break;
      case "monthly":
        endDate.setMonth(endDate.getMonth() + 1);
        break;
      case "quaterly":
        endDate.setMonth(endDate.getMonth() + 3);
        break;
      case "half yearly":
        endDate.setMonth(endDate.getMonth() + 6);
        break;
      case "yearly":
        endDate.setFullYear(endDate.getFullYear() + 1);
        break;
      default:
        return endDate;
    }
    return endDate;
  }

  const initialEndDate = calculateEndDate(storedPlanName, initialStartDate);

  localStorage.setItem("startDate", initialStartDate.toISOString());
  localStorage.setItem("endDate", initialEndDate.toISOString());

  useEffect(() => {
    const sessionId = new URLSearchParams(window.location.search).get(
      "session_id"
    );
    localStorage.setItem("sessionId", sessionId);
    if (sessionId && !localStorage.getItem("transactionSaved")) {
      // Fetch the full session details from the backend
      axios
        .get(
          `https://stripe.oneaichat.com/checkout-session?sessionId=${sessionId}`
        )
        .then((response) => {
          const { stripeData, planName, planPrice } = response.data;
          // Set the response to the state
          setStripeResponse(response.data);
          setPlanName(planName);
          setPlanPrice(planPrice);
          // Fetch plan details from local storage
          const selectedPlanName = localStorage.getItem("selectedPlanName");
          const selectedPlanPrice = localStorage.getItem("selectedPlanPrice");
          // Store the Stripe details in localStorage
          localStorage.setItem("stripeDetails", JSON.stringify(response.data));
          const { amount, currency } = response.data;
          const authUserString = localStorage.getItem("authUser");

          // Parse the JSON string to an object
          const authUser = authUserString ? JSON.parse(authUserString) : null;

          // Check if authUser exists and has the _id field
          const userid = authUser._id;
          const email = authUser.email;
          // Log the userId
          console.log("useriddddddddddddddddd", userid);
          // Send to backend
          const dataToSend = {
            sessionId,
            userid,
            // stripeSessionId: sessionId,
            stripeData: response.data,
            email: email,
            amount: amount,
            currency: currency,
            planName: selectedPlanName,
            planPrice: selectedPlanPrice,
            startDate: localStorage.getItem("startDate"),
            endDate: localStorage.getItem("endDate"),
          };
          if (!localStorage.getItem("transactionSaved")) {
            axiosInstance
              .post(`/save-stripe-data`, dataToSend)
              .then((response) => {
                console.log("Data saved:", response);
                // Clear the selected plan details from local storage
                localStorage.removeItem("selectedPlanName");
                localStorage.removeItem("selectedPlanPrice");
                localStorage.removeItem("startDate");
                localStorage.removeItem("endDate");
                localStorage.setItem("transactionSaved", "true");

                // Fetch transaction data after saving the data
                axiosInstance
                  .get(`/get-transaction-data?sessionId=${sessionId}`)
                  .then((response) => {
                    console.log("Axios response:", response);
                    if (response.data.success) {
                      const { data: transactionData } = response.data;
                      console.log("Transaction data:", transactionData);
                      setTransactionDetails(transactionData); // Set transaction data to state
                      // Perform any necessary operations with the transaction data
                    } else {
                      console.error(
                        "Transaction data not found:",
                        response.data.message
                      );
                      // Handle the case where transaction data is not found
                    }
                  })
                  .catch((error) => {
                    console.error(
                      "Error fetching transaction data:",
                      error.response ? error.response.data : error.message
                    );
                    // Handle error
                  });
              })
              .catch((error) => {
                console.error(
                  "Error saving data:",
                  error.response ? error.response.data : error.message
                );
              });
          }
        });
    }
  }, []);

  const [transactionDetails, setTransactionDetails] = useState(null);
  return (
    <div>
      <center>
        <img
          src="/assets/img/logo/2.jpeg"
          alt="logo"
          style={{ width: "175px", height: "165px", display: "block" }}
        />
      </center>
      {transactionDetails && (
        <table>
          <tbody>
            <tr>
              <td>Status Description:</td>
              <td>
                {transactionDetails.payment_status || transactionDetails.code}
              </td>
            </tr>
            <tr>
              <td>Payment Mode:</td>
              <td>Online Payment</td>
            </tr>
            <tr>
              <td>Session ID:</td>
              <td>{transactionDetails.sessionId}</td>
            </tr>
            {/* <tr>
              <td>Order ID:</td>
              <td>{transactionDetails.order_id ||transactionDetails.providerReferenceId}</td>
            </tr> */}

            <tr>
              <td>Customer Email:</td>
              <td>{transactionDetails.email}</td>
            </tr>
            <tr>
              <td>Transaction Date:</td>
              <td>{transactionDetails.transaction_date}</td>
            </tr>
            <tr>
              <td>Plan Price:</td>
              <td>{transactionDetails.planPrice}</td>
            </tr>
            <tr>
              <td>Authorization Status:</td>
              <td>{transactionDetails.authStatus || "0300"}</td>
            </tr>
            <tr>
              <td>Plan Name:</td>
              <td>{transactionDetails.planName}</td>
            </tr>
            {/* <tr>
              <td>Plan Id:</td>
              <td>{transactionDetails.plan_id ||transactionDetails.planId}</td>
            </tr> */}
            <tr>
              <td>Start Date:</td>
              <td>{transactionDetails.startDate}</td>
            </tr>
            <tr>
              <td>End Date:</td>
              <td>{transactionDetails.endDate || "na"}</td>
            </tr>
            <tr>
              <td>Payment Method:</td>
              <td>{transactionDetails.payment_method}</td>
            </tr>
          </tbody>
        </table>
      )}
      <center>
        <button
          className="retrieve-button"
          onClick={() => {
            localStorage.removeItem("transactionSaved");
            window.location.href = "/";
          }}
        >
          Back To Home
        </button>
      </center>
      {/* Add other fields as needed */}
    </div>
  );
}

export default PaymentSuccess;
