// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Default styles for mobile */

.position-relative {
  flex-direction: column;
  gap: 10px; /* Space between input and button */
}

.form-control {
  width: 100%;
  padding: 10px;
}

.aai-newsletter-btn {
  padding: 8px;
}

/* Styles for larger screens (web) */

@media (min-width: 768px) {
  .position-relative {
    flex-direction: row;
    align-items: center;
  }

  .form-control {
    flex-grow: 1; /* allows the input to take up the available space */
    margin-right: 3px; /* Space between input and button */
  }

  .aai-newsletter-btn {
    width: auto;
  }
}

.aai-footer-social {
  display: flex;
  justify-content: center;
  gap: 35px;
}

.aai-social-link {
  font-size: 25px;
  color: #000;
  transition: color 0.3s ease, transform 0.3s ease;
}

.aai-social-link:hover {
  color: rgb(252, 179, 22); /* Change this to your desired hover color */
  transform: scale(1.2);
}
`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.css"],"names":[],"mappings":"AAAA,8BAA8B;;AAE9B;EACE,sBAAsB;EACtB,SAAS,EAAE,mCAAmC;AAChD;;AAEA;EACE,WAAW;EACX,aAAa;AACf;;AAEA;EACE,YAAY;AACd;;AAEA,oCAAoC;;AAEpC;EACE;IACE,mBAAmB;IACnB,mBAAmB;EACrB;;EAEA;IACE,YAAY,EAAE,oDAAoD;IAClE,iBAAiB,EAAE,mCAAmC;EACxD;;EAEA;IACE,WAAW;EACb;AACF;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,SAAS;AACX;;AAEA;EACE,eAAe;EACf,WAAW;EACX,gDAAgD;AAClD;;AAEA;EACE,wBAAwB,EAAE,4CAA4C;EACtE,qBAAqB;AACvB","sourcesContent":["/* Default styles for mobile */\n\n.position-relative {\n  flex-direction: column;\n  gap: 10px; /* Space between input and button */\n}\n\n.form-control {\n  width: 100%;\n  padding: 10px;\n}\n\n.aai-newsletter-btn {\n  padding: 8px;\n}\n\n/* Styles for larger screens (web) */\n\n@media (min-width: 768px) {\n  .position-relative {\n    flex-direction: row;\n    align-items: center;\n  }\n\n  .form-control {\n    flex-grow: 1; /* allows the input to take up the available space */\n    margin-right: 3px; /* Space between input and button */\n  }\n\n  .aai-newsletter-btn {\n    width: auto;\n  }\n}\n\n.aai-footer-social {\n  display: flex;\n  justify-content: center;\n  gap: 35px;\n}\n\n.aai-social-link {\n  font-size: 25px;\n  color: #000;\n  transition: color 0.3s ease, transform 0.3s ease;\n}\n\n.aai-social-link:hover {\n  color: rgb(252, 179, 22); /* Change this to your desired hover color */\n  transform: scale(1.2);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
