import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const StripeFail = () => {
  const [sessionId, setSessionId] = useState("");
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const session_id = queryParams.get("session_id");
    setSessionId(session_id || "Transaction ID not found");
  }, [location]);

  return (
    <div
      className="container py-5 d-flex flex-column justify-content-center align-items-center"
      style={{ minHeight: "100vh" }}
    >
      <div className="text-center">
        <img
          src="/assets/img/logo/2.jpeg"
          alt="Logo"
          className="img-fluid"
          style={{ width: "175px", height: "165px" }}
        />
        <h2 className="my-4">Transaction Failed</h2>
        <p className="text-muted mb-4">
          Please note the session ID if your amount was debited and the
          transaction has failed.
        </p>
      </div>
      <div className="d-flex justify-content-center">
        <div className="card shadow-sm" style={{ maxWidth: "fit-content" }}>
          <div className="card-body">
            <h5 className="card-title text-danger text-center">
              Payment Failure
            </h5>
            <table
              className="table table-striped table-bordered"
              style={{
                margin: "0 auto",
                wordWrap: "break-word",
                width: "100%",
              }}
            >
              <tbody>
                <tr>
                  <td style={{ width: "50%" }}>Status Description:</td>
                  <td>
                    <strong>Payment Failed</strong>
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>Payment Mode:</td>
                  <td>
                    <strong>Stripe</strong>
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "50%" }}>Session ID:</td>
                  <td>
                    <div
                      style={{
                        wordWrap: "break-word",
                        whiteSpace: "pre-wrap",
                        maxWidth: "100%",
                      }}
                    >
                      {sessionId}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="text-center mt-4">
        <button
          className="btn btn-primary btn-lg"
          onClick={() => (window.location.href = "/")}
        >
          Back to Home
        </button>
      </div>
    </div>
  );
};

export default StripeFail;
